@tailwind base;
@tailwind components;
@tailwind utilities;

.searchForm {
    overflow: visible !important;
}

.hero-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../public/images/hero.jpg");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}

.searchForm {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3000 !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



.text-hero {
    text-align: start;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 3000 !important;

}

header {
    overflow: visible !important;
}

.bookBtn {
    background-color: #1E88E5 !important;
    color: white !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    width: 100%;
    height: 45px !important;
    border-radius: 8px !important;
}

.incBtn {
    background-color: #1E88E5 !important;
    color: #fff !important;
}

.flightCard {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


.orangeBtn {
    background-color: orange !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.btn {
    text-transform: none !important;
}

.navOpacity {
    background-color: rgba(0, 0, 0, 0.545);
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.zoom:hover {
    transform: scale(1.05);
    transition: 0.5s ease;

    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}